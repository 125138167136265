<template>
  <div class="top-toolbar">
    <div class="containerToolbar" :class="{ 'ru-locale': $i18n.locale === 'ru' }">
      <div class="icon-item leaderboard" @click="showSeasonModal = true">
        <img v-if="store.isNewYearTheme" class="leaderboard-new-year" src="@/public/img/newYear/primary.png" />
        <img class="icon-image" src="@/public/img/season_cup.png" alt="" />
        <UIChevron
          v-if="address && !BigNumber(playerPosition || 0).isZero()"
          :type="ChevronType.Leader"
          class="orange-plate"
        >
          <span>
            {{ playerPosition }}
          </span>
        </UIChevron>
        <UIChevron class="bottom-plate">
          <span>{{ `${!timeLeft?.ended.value ? timeLeft?.days.value : ''} ${seasonsDaysLeftText}` }}</span>
        </UIChevron>
      </div>
      <div v-if="isUnclaimedPositions" class="icon-item icon-item-green" @click="claimButtonClickHandler">
        <img v-if="store.isNewYearTheme" class="claim-new-year" src="@/public/img/newYear/claim.png" />
        <img class="icon-image icon-token-image" src="/public/img/season_chest.png" alt="" />
        <UIChevron class="bottom-plate">
          <span>{{ $t('referralClaimButtonLabel') }}</span>
        </UIChevron>
      </div>

      <div class="icon-item seller-icon" @click="sellerButtonClickHandler">
        <img class="icon-image icon-token-image" src="/public/img/mobileTopToolbar/sellerIcon.png" alt="" />
        <UIChevron
          :type="Boolean(sellerData?.length) ? ChevronType.Highlighted : ChevronType.Inactive"
          class="bottom-plate"
        >
          <span>{{ $t(sellerData?.length ? 'coreOpen' : 'coreClosed') }}</span>
        </UIChevron>
      </div>

      <div class="icon-item" @click="showStoryModal = true">
        <img class="icon-image" src="@/public/img/sideBar/storyIcon.png" alt="" />
      </div>
      <div class="icon-item" @click="handleStorageClick">
        <img class="icon-image" src="@/public/img/sideBar/storageIcon.png" alt="" />
      </div>
    </div>

    <StorageWrapper
      :is-storage-selected="showStorageModal"
      :selected-building="selectedBuilding"
      :description="'storage_description'"
      @closed="showStorageModal = false"
    />
    <el-dialog
      v-model="showSeasonModal"
      class="seasonModal"
      :class="{
        seasonModalMobile: isMobile,
        rewards: seasonModalViewProp === SeasonModalView.rewards,
        minContentWidth: seasonModalViewProp === SeasonModalView.end || seasonModalViewProp === SeasonModalView.rewards,
        minHeight: seasonModalViewProp !== SeasonModalView.end && seasonModalViewProp !== SeasonModalView.rewards,
        'ru-locale': $i18n.locale === 'ru'
      }"
      :fullscreen="isMobile"
      :title="seasonModalTitle"
      :center="true"
      :append-to-body="true"
      @closed="
        () => {
          seasonModalViewProp = null;
          selectedBuilding = null;
        }
      "
    >
      <SeasonModal
        :view="seasonModalViewProp"
        @update-modal-header="
          (value: string) => {
            seasonModalTitle = value;
          }
        "
        @close="showSeasonModal = false"
      />
    </el-dialog>

    <el-dialog
      v-model="showStoryModal"
      class="history-modal"
      :class="{ 'without-header': true, 'ru-locale': $i18n.locale === 'ru' }"
      :fullscreen="isMobile"
      :title="$t('historyModalStory')"
      :append-to-body="true"
      :center="true"
      @closed="selectedBuilding = null"
    >
      <!-- @run-animation="runAnimation" -->
      <history-modal />
    </el-dialog>

    <SellerModal @closed="showSellerModal = false" />
  </div>
</template>

<script setup lang="ts">
import { useWeb3ModalAccount } from '@web3modal/ethers/vue';
import { inject, ref } from 'vue';
import type { TNullable } from '~/types/common';
import type { BuildingProps } from '~/types/crafting';
import { SeasonModalView } from '~/types/season';
import { BigNumber } from 'bignumber.js';
import type { IUserModel } from '~/types/apiService';
import type { SellerItem } from '~/types/seller';
import { ChevronType } from '~/utils/constants/ui';

const { address } = useWeb3ModalAccount();

const { t } = useI18n();
const store = useMainStore();
const { isMobile } = useDevice();
const { login } = useAuth();
const { data: userData } = useNuxtData<IUserModel>('userData');

const showStoryModal = ref(false);
const showStorageModal = ref(false);
const selectedBuilding = ref<TNullable<BuildingProps>>(null);
const seasonModalViewProp = ref<TNullable<SeasonModalView>>(null);

const currentSeasonId = inject<number>('currentSeasonId');
const playerPosition = inject<Ref<number, number>>('playerPosition');
const sellerData = inject<Ref<SellerItem[]>>('sellerData');

const timeLeft = inject<{
  seconds: Ref<number, number>;
  minutes: Ref<number, number>;
  hours: Ref<number, number>;
  days: Ref<number, number>;
  months: Ref<number, number>;
  ended: Ref<boolean, boolean>;
}>('timeLeft');
const seasonsDaysLeftText = inject<Ref<string, string>>('seasonsDaysLeftText');
const isUnclaimedPositions = inject<Ref<boolean, boolean>>('isUnclaimedPositions');

const showSeasonModal = ref(false);
const showSellerModal = ref(false);
provide('showSellerModal', showSellerModal);
provide('showSeasonModal', showSeasonModal);

const seasonModalTitle = ref(t(`seasonName${currentSeasonId}`));

const claimButtonClickHandler = () => {
  seasonModalViewProp.value = SeasonModalView.rewards;
  showSeasonModal.value = true;
};

const sellerButtonClickHandler = () => {
  if (sellerData && !sellerData.value?.length) {
    return;
  }

  showSellerModal.value = true;
};

const handleStorageClick = async () => {
  if (!userData.value) {
    await login();
    return;
  }

  showStorageModal.value = true;
};
</script>

<style lang="scss">
.history-modal {
  .el-dialog__body {
    padding-top: 0px;
  }

  .card {
    @media (max-width: 479px) {
      overflow-y: unset;
    }
  }
}
</style>

<style lang="scss" scoped>
.top-toolbar {
  top: 90px;
  left: 0;
  position: absolute;
  z-index: 4;
  width: 100%;
  user-select: none;

  .containerToolbar {
    display: flex;
    justify-content: space-between;
    gap: 45px;
    align-items: center;
    overflow-x: auto;
    scrollbar-width: thin;
    min-height: 80px;
    padding: 0 16px;
  }

  .icon-item {
    pointer-events: all;
    cursor: pointer;
    border-radius: 31px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    transition: border 0.2s;
    position: relative;
    background: radial-gradient(at -0% -30%, rgb(37, 78, 93) 30%, rgb(6, 21, 29) 60%);
    border: 1px solid #3b7285;
    width: 56px;
    min-width: 56px;
    min-height: 56px;
    height: 56px;

    &:hover {
      border: 1px solid #bc944d;
    }

    .icon-image {
      display: block;
      width: 70%;
      height: 70%;
    }

    .icon-token-image {
      position: absolute;
      width: 120%;
      height: 120%;
    }
  }

  .seller-icon img {
    transform: scale(0.75);
  }

  .bottom-plate {
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 14px;
  }

  .orange-plate {
    position: absolute;
    bottom: 19px;
  }

  .leaderboard {
    width: 64px;
    min-width: 64px;
    height: 64px;
    min-height: 64px;
    border-color: #fff;

    .icon-image {
      width: 51px;
      height: 51px;
      position: absolute;
      top: -12px;
    }
  }

  .leaderboard-new-year {
    position: absolute;
    height: 75px;
    top: -8px;
    left: -13px;
    z-index: 0;
  }

  .claim-new-year {
    position: absolute;
    height: 75px;
    top: -8px;
    left: -18px;
    z-index: 0;
  }

  .icon-item-green {
    background: linear-gradient(#60ff7c 5%, #04202b 110%);
  }
}
</style>

<style>
.el-dialog.myProfileModal {
  min-width: 300px;
}

.seasonModal.seasonModalMobile {
  &.ru-locale {
    .switcherOptionLabel {
      line-height: 1;

      @media screen and (max-width: 479px) {
        width: min-content;
      }
    }
  }

  .el-carousel__container > svg {
    display: none;
  }

  .btn-primary {
    width: auto;
  }

  .claimBlock {
    margin-top: 20px;
  }

  .el-dialog__title {
    font-size: 30px;
  }

  .seasonDescription {
    font-size: 16px;
  }

  .statisticWrapper {
    .statisticItem {
      .label {
        font-size: 18px;
      }

      .value {
        font-size: 32px;
      }
    }
  }

  .el-carousel__arrow {
    .el-icon {
      display: none;
    }
  }

  .el-carousel__arrow--left,
  .el-carousel__arrow--right {
    height: 48px;
    width: 48px;
    backdrop-filter: blur(5px);
    background: #0c1920;
    border-radius: 15px;
    border: solid 1px #1e353e21;
  }

  .el-carousel__arrow--left:after,
  .el-carousel__arrow--right:after {
    content: '';
    display: block;
    height: 24px;
    width: 24px;
  }

  .el-carousel__arrow--left:after {
    background-image: url('~/public/img/icons/singleArrowLeft.svg');
  }

  .el-carousel__arrow--right:after {
    background-image: url('~/public/img/icons/singleArrowRight.svg');
  }

  @media screen and (max-width: 479px) {
    overflow-y: auto;
    overflow-x: hidden;

    .el-dialog__header.show-close {
      padding-top: 4px;
    }

    .tokensWrapper {
      margin: 0 10px;
    }

    .seasonStartContainer {
      gap: 25px;

      .highlightedCardContainer {
        margin-top: 10px;
        width: 100%;
      }

      .tokensWrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;

        & > :last-child {
          grid-column: span 2 / span 2;
        }

        .tokenItem {
          span {
            text-align: center;
            font-size: 24px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 760px) {
    .highlightedCardContainer {
      width: min-content;
    }

    .tokensWrapper {
      margin: 0 10px;
    }
  }
}
</style>
